<!--产品与服务-->
<template>
  <div class="product-service-container">
    <new-cloude-header/>
    <div class="product-header">
      <div class="product--title">
        <div class="product-left">
          <p class="large-title">产品与服务</p>
          <p class="normal-p">帮企服务云提供专业、可靠、可信的产品服务，全面覆盖业务所需，为用户提供极佳体验。</p>
        </div>
        <div class="product-right"/>
      </div>
    </div>
    <div class="float-layer">
      <el-row>
        <el-col :span="6">
          <div>
            <p class="bold-p">全链</p>
            <p class="darkness-p">提供全链业务数字化场景的产品服务体系</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <p class="bold-p">敏捷</p>
            <p class="darkness-p">强大的PaaS能力，帮助企业敏捷高效交付</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <p class="bold-p">开放</p>
            <p class="darkness-p">系统满足端到端的服务能力生态</p>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <p class="bold-p">可靠</p>
            <p class="darkness-p">强大的技术中台能力支撑全链业务</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--热门行业解决方案 -->
    <div class="product-content">
      <div class="product-title">
        <p class="title">性能强大、安全、稳定的产品</p>
        <p class="hot-tips">让每个领域数字化难题迎刃而解</p>
      </div>
      <div class="hot-industry">
        <el-row :gutter="20">
          <template v-for="list of productsList">
            <el-col :span="8">
              <div class="product-col">
                <p class="col-title" @click="productDetails(list)">{{ list.title }}</p>
                <p class="col-introduce">{{ list.introduce }}</p>
                <el-button type="primary" plain class="product-button" @click="bannerClick(list.title)">申请试用</el-button>
                <el-link :underline="false" class="product-link" @click="productDetails(list)">了解详情
                  <i class="el-icon-arrow-right el-icon--right"/>
                </el-link>
              </div>
            </el-col>
          </template>
        </el-row>
      </div>
    </div>
    <div class="product-service">
      <div class="product-service-content">
        <div class="product-title">
          <p class="title">六类技术能力支撑</p>
          <p class="hot-tips">依托在行业信息化领域的多年探索、实践以及丰富的实践案例</p>
        </div>
        <div class="product-service-col">
          <el-row :gutter="20">
            <el-col :span="8">
              <div class="product-col-1">
                <p class="col-title">流程化</p>
                <p class="col-introduce">流程管理贯穿人力资源管理全业务，让结果有据可依，更加合规高效。</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="product-col-2">
                <p class="col-title">协同化</p>
                <p class="col-introduce">不仅仅是人事相关的数据，所有与组织相关，与员工相关的数据均可协同。</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="product-col-3">
                <p class="col-title">数字化</p>
                <p class="col-introduce">所有人力资源相关元素数字化分类存放在系统中，以便于按需分类汇总。</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="product-col-4">
                <p class="col-title">智能化</p>
                <p class="col-introduce">融入RPA、语音识别、OCR等技术，让您的人事系统更加智能与便捷。</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="product-col-5">
                <p class="col-title">全程数字化</p>
                <p class="col-introduce">不再填写表格与手工签署，在职证明、收入证明等均可通过契约锁签署。</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="product-col-6">
                <p class="col-title">无代码化</p>
                <p class="col-introduce">数智应用低代码平台可快速支撑个性化需求，不懂代码也可快速搭建。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <cases/>
    <new-cloude-footer/>
    <applyTrial ref="dialog" :servicePlatform="servicePlatform"/>
  </div>
</template>
<script>
import NewCloudeFooter from "@/views/home/newCloudeFooter";
import NewCloudeHeader from "@/views/home/newCloudeHeader";
import cases from "@/components/case/cases";
import applyTrial from "@/components/assembly/applyTrial";
export default {
  name: "index",
  components: {cases, NewCloudeFooter, NewCloudeHeader,applyTrial},
  data() {
    return {
      productsList: [
        {
          title: "SCM供应链管理系统",
          introduce: "整合和优化供应链的各个环节，如采购、生产、配送和销售，提高供应链的效率和可靠性。实现数据共享和协同工作，减少不必要的沟通和误解，提高工作效率和协作能力。",
          url: '/SCMsystem'
        },
        {
          title: "ERP企业资源系统",
          introduce: "实现对整个供应链的有效管理，包括采购、库存、销售、财务等各个环节，提高供应链的效率和降低成本。实现对企业资源的集成和管理，包括客户、项目、合同、人力资源等各类资源，提高资源的利用率和价值。",
          url: '/ERPsystem'
        },
        {
          title: "CRM客户关系管理系统",
          introduce: "帮助企业获得客户，通过多种渠道收集和管理客户信息，实现对客户需求的及时响应和满足。帮助企业管理客户，通过建立客户档案、跟踪客户行为、分析客户价值等方式，实现对客户的全面了解和分类",
          url: '/CRMsystem'
        },
        {
          title: "MES制造执行系统",
          introduce: "监控和管理制造过程，实现生产现场的透明化，提供实时、准确的生产数据，支持生产计划的执行和调度。优化和整合生产资源，包括设备、工具、人员、物料等，提高资源的利用率和效率，降低生产成本。",
          url: '/MESsystem'
        },
        {
          title: "财务管理系统",
          introduce: "融合最新的财务管理模式、数智技术与企业最佳实践，构建费用管理、财务会计、财务共享、财务中台、税务管理、管理会计、企业绩效、资金管理等新一代财务云应用，助力企业财务数字化转型。",
          url: '/finance'
        },
        {
          title: "WMS仓库管理系统",
          introduce: "通过数据采集设备，如条码扫描器、RFID读写器等，实现对仓库中的物品信息的准确采集和识别。通过智能化的任务分派和接收功能，指导仓库操作人员完成入库、出库、盘点、移库等各种库内作业。",
          url: '/warehouse'
        },
        {
          title: "KMS知识管理系统",
          introduce: "KMS系统适用于管理知识资源的软件系统，包括知识库管理、知识分类和标签、搜索引擎、协作与分享等功能模块，帮助企业提高知识管理和利用效率。",
          url: '/kmsKnowledge'
        },
        {
          title: "PLM产品生命周期管理系统",
          introduce: "如何有效地缩短产品研发周期、改善产品服务的设计迭代流程、有效降低产品的研发成本，也是企业面临的主要问题之一。PLM产品生命周期管理系统在优化产品研发的全管理流程、增强企业产品的市场竞争力方面具有关键的作用。",
          url: '/plm'
        },
        {
          title: "CMS合同管理系统",
          introduce: "帮企数智云专注于合同管理的品牌，通过强大的低代码平台、流程引擎与丰富组件，落地合同审批规则，构建全程数字化的合同管理平台，让合同管理更加高效、合规、安全、协同。",
          url: '/cms'
        },
        {
          title: "HRM人力资源管理系统",
          introduce: "从本质上释放HR的双手，提高工作效能，提升员工使用体验，深入数据洞察，助力企业科学智慧决策，开放的Paas平台，满足企业集团化、个性化管理需求。",
          url: '/hrm'
        },
        {
          title: "PMS项目管理系统",
          introduce: "PMS覆盖不同项目类型全生命周期管理，通过对业务流程及业务对象建模，通过对需求分析、项目立项管理、项目变更管理等多种企业业务流程的数字化建模，对项目过程中的项目、问题、风险等业务对象的数字化建模，实现项目全生命周期的数字化管理，为高层精准决策、中层监控全面、基层高效执行、及经验及时沉淀与分享提供保障。",
          url: '/pms'
        },
        {
          title: "BPM业务流程管理系统",
          introduce: "BPM是整个协同办公平台的血脉，它是连接并打通其它各个应用模块之间协同的关键所在。通过BPM，既可以帮助用户基于企业业务模式和管理模式，自行定义所需要的各种流程应用，快速构建企业自身的流程管控体系，同时也为建设企业整体协同平台夯实基础。",
          url: '/bpm'
        },
        {
          title: "会议管理系统",
          introduce: "采购、招标、供应链协同、合同管理的全流程管理系统...",
          url: '/meeting'
        },
        {
          title: "SRM采购管理系统",
          introduce: "SRM采购管理系统，1000 +采购功能、20 +行业全场景覆盖，为不同规模企业提供差异化的采购管理系统开发，从需求到寻源，订单到对账开票覆盖整体采购业务，真正做到对采购每个环节进行严密的跟踪与管理，推动采购战略执行，全面提升效率与管理体验。",
          url: '/srm'
        },
        {
          title: "数字应用",
          introduce: "基于组件化开发思想，平台打通应用软件从需求、涉及、开发、构建、测试、发布到部署的全流程，通过持续的调用与迭代，将丰富的项目积累和经验工具化、组件化、模板化，通过统一操作页面，真正做到让中小企业用户“开箱即用”。可以自由选用工具组件、业务应用、表单模板、智能化助手及解决方案；轻松实现流程、审批、报表、页面、数据处理等功能；按需生成Web应用、APP、大屏可视化应用、小程序等操作起来很方便，只需一键调用、简单“拖拽”和配置，就能在图形化的界面上，快速实现个性化业务功能定制，轻松完成应用搭建。",
          url: '/digitalApplications'
        },
        {
          title: "OA办公系统",
          introduce: "促进企业协同办公，提高工作效率和管理水平。规范化管理企业的各种工作流程，实现在线审批、签字、盖章等操作，节省时间和成本。支持移动办公，使员工和管理者能够跨越时空障碍，随时随地进行协作和沟通。记录和跟踪企业的各类业务数据，实现对人员、资产、客户等的全面管理和控制。",
          url: '/OAsystem'
        },
        {
          title: "客服管理系统",
          introduce: "人工智能客服自从开始进入市场后，受到了各行各业的认可，其功能可以在银行、电商、地产、家居、汽车等灵活应用，满足企业的运营和业务需求。到目前，从使用企业反馈的数据情况看，智能客服可以降低人工消耗，把重复性问题，简单性咨询或是初次联系的客户交予智能客服，让人工客服集中注意力服务后续意向性更强的客户。智能客服确实是可以有效提高工作效率，满足企业的业务需求，提高交易量，提升企业运营效率。",
          url: '/customer'
        },
        {
          title: "档案管理系统", 
          introduce: "档案管理系统，为归档单位提供先进的数字档案室（馆）信息化解决方案，实现档案数字化、信息化、标准化和智慧化。", 
          url: '/record'
        },
        {
          title: "千企云-低代码开发平台", 
          introduce: "为企业提供快速应用开发能力的平台，通过可视化拖拽的方式，让用户无需具备专业的编程技能也能轻松开发出满足业务需求的应用程序。该平台内置了丰富的功能模块和行业解决方案，帮助企业实现数字化转型，提升业务效率", 
          url: '/QianQiCloud'
        },
        {
          title: "OMS系统", 
          introduce: "一套为企业设计的电子商务订单处理软件，旨在帮助企业高效管理在线和离线订单流程。该系统支持订单接收、处理、配送以及退换货等全方位服务，同时提供库存管理、客户关系管理和数据分析等功能，确保企业能够快速响应市场变化，提升顾客满意度，并优化运营效率 9001等国际质量管理标准，通过系统化、标准化的流程控制，确保企业能够持续提供符合客户要求和法规要求的高质量产品和服务", 
          url: '/OMSsystem'
        },
        {
          title: "QMS系统", 
          introduce: "一套为企业设计的电子商务订单处理软件，旨在帮助企业高效管理在线和离线订单流程。该系统支持订单接收、处理、配送以及退换货等全方位服务，同时提供库存管理、客户关系管理和数据分析等功能，确保企业能够快速响应市场变化，提升顾客满意度，并优化运营效率 9001等国际质量管理标准，通过系统化、标准化的流程控制，确保企业能够持续提供符合客户要求和法规要求的高质量产品和服务", 
          url: '/QMSsystem'
        },
      ],
      servicePlatform:'',
    }
  },
  methods: {
    productDetails(list) {
      this.$router.push({path: list.url});
    },
    bannerClick(servicePlatform){
      this.servicePlatform=servicePlatform;
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style scoped lang="scss">
.product-service-container {
  .product-header {
    height: 400px;
    background: url("~@/assets/img/solution/beijjing.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .product--title {
      width: 1100px;
      margin: auto;
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      padding-right: 100px;

      .product-left {
        margin-top: 120px;

        .large-title {
          font-weight: 500;
          font-size: 36px;
          color: #FFFFFF;
          margin-block-end: 20px;
          margin-block-start: 0px;
          font-family: 'SourceHanSansCN';
        }

        .normal-p {
          font-size: 16px;
          color: #FFFFFF;
          margin-block-end: 0px;
          margin-block-start: 0px;
          font-family: 'SourceHanSansCN';
        }
      }
    }

    .product-right {
      width: 340px;
      height: 394px;
      background: url("~@/assets/img/solution/数智管理.png") no-repeat;
      background-size: 100% 100%;
    }
  }

  .float-layer {
    width: 1180px;
    background: #FFFFFF;
    box-shadow: 0px 3px 21px 0px rgba(169, 169, 169, 0.32);
    padding: 30px 10px;
    margin: auto;
    margin-top: -90px;
    position: sticky;

    .el-col-6 {
      width: 25%;
      padding: 0px 15px;
      border-right: 1px solid #DDDDDD;

      .darkness-p {
        font-size: 14px;
        color: #666666;
        font-family: 'SourceHanSansCN';
        margin-block-start: 10px;
        margin-block-end: 0px;
      }

      .bold-p {
        font-size: 18px;
        color: #333333;
        font-family: 'SourceHanSansCN';
        margin-block-start: 0px;
        margin-block-end: 0px;
      }
    }

    .el-col-6:last-child {
      border-right: none;
    }
  }

  /*热门行业解决方案*/
  .product-content {
    width: 1200px;
    margin: auto;
    padding: 60px 0px 30px;
    background: #FFFFFF;

    .hot-industry {
      margin-top: 40px;

      .product-col {
        border: 1px solid #DDDDDD;
        padding: 30px 20px;
        margin-bottom: 20px;
        background: #FFFFFF;
        height: 140px;
        cursor: pointer;

        .col-title {
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          color: #333333;
          margin-block-start: 0px;
          margin-block-end: 20px;
        }

        .col-introduce {
          font-size: 14px;
          font-family: 'SourceHanSansCN';
          color: #333333;
          margin-block-start: 0px;
          margin-block-end: 20px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .product-button {
          color: #1935DE;
          border: 1px solid #1935DE;
          border-radius: 0px;
          background: #FFFFFF;
          font-family: 'SourceHanSansCN';
          font-size: 14px;
        }

        .product-link {
          color: #333333;
          font-family: 'SourceHanSansCN';
          font-size: 14px;
          margin-left: 20px;
        }
      }

      .product-col:hover {
        background: #1935DE;
        box-shadow: 0px 3px 5px 0px rgba(93, 103, 160, 0.71);

        .col-title {
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          color: #FFFFFF;
          margin-block-start: 0px;
          margin-block-end: 20px;
        }

        .col-introduce {
          font-size: 14px;
          font-family: 'SourceHanSansCN';
          color: #FFFFFF;
          margin-block-start: 0px;
          margin-block-end: 20px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .product-button {
          color: #FFFFFF;
          border: 1px solid #FFFFFF;
          border-radius: 0px;
          background: #1935DE;
          font-family: 'SourceHanSansCN';
          font-size: 14px;
        }

        .product-link {
          color: #FFFFFF;
          font-family: 'SourceHanSansCN';
          font-size: 14px;
          margin-left: 20px;
        }
      }
    }
  }

  .product-title {
    text-align: center;

    .title {
      font-size: 30px;
      font-family: 'SourceHanSansCN';
      color: #333333;
      margin-block-start: 0px;
      margin-block-end: 0px;
      font-weight: 500;
    }

    .hot-tips {
      font-family: 'SourceHanSansCN';
      color: #666666;
      font-size: 18px;
      margin-block-start: 10px;
      margin-block-end: 0px;
    }
  }

  .product-service {
    background: #F5FAFE;
    padding: 60px 0px 60px;

    .product-service-content {
      .product-service-col {
        width: 1200px;
        margin: auto;
        margin-top: 40px;

        .product-col-1 {
          padding: 30px;
          background: linear-gradient(-30deg, rgba(194, 203, 255, 0.99), rgba(240, 248, 255, 0.99));
          margin-bottom: 20px;
          cursor: pointer;
        }

        .product-col-2 {
          padding: 30px;
          background: linear-gradient(-30deg, rgba(255, 191, 171, 0.99), rgba(255, 242, 238, 0.99));
          margin-bottom: 20px;
          cursor: pointer;
        }

        .product-col-3 {
          padding: 30px;
          cursor: pointer;
          margin-bottom: 20px;
          background: linear-gradient(-30deg, rgba(166, 255, 233, 0.99), rgba(228, 255, 248, 0.99));
        }

        .product-col-4 {
          padding: 30px;
          cursor: pointer;
          background: linear-gradient(-30deg, rgba(255, 151, 161, 0.99), rgba(255, 237, 239, 0.99));
        }

        .product-col-5 {
          padding: 30px;
          cursor: pointer;
          background: linear-gradient(-30deg, rgba(255, 216, 159, 0.99), rgba(255, 248, 237, 0.99));
        }

        .product-col-6 {
          padding: 30px;
          cursor: pointer;
          background: linear-gradient(-30deg, rgba(139, 196, 255, 0.99), rgba(225, 234, 255, 0.99));
        }

        .col-title {
          font-family: 'SourceHanSansCN';
          font-size: 18px;
          color: #333333;
          margin-block-start: 0px;
          margin-block-end: 20px;
        }

        .col-introduce {
          font-size: 14px;
          font-family: 'SourceHanSansCN';
          color: #333333;
          margin-block-start: 0px;
          margin-block-end: 20px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>
