<template>
  <div class="case-container">
    <div class="case-title">
      <p class="title">客户案例</p>
      <p class="case-tips">我们致力于通过专业的沟通、创新的理念、优质的服务专注赢得每一位客户的信任和口碑</p>
    </div>
    <div class="case-content">
      <div class="case-left">
        <div class="case--content">
          <p class="case--title">{{ caseInfo.customerStories.caseTitle }}</p>
          <p class="content" v-if="caseInfo.customerStories.enterpriseIntroduction" v-html="caseInfo.customerStories.enterpriseIntroduction"/>
          <div class="case-content-bottom">
            <el-link :underline="false" @click="caseDetails">更多详情</el-link>
            <div class="page-container">
              <el-link :underline="false" :disabled="tablePage.currentPage==1 ? true : false" @click="lastPage"><i class="el-icon-back"/></el-link>
              <p class="page-size"><span>{{ tablePage.currentPage }}</span>/<span>{{ tablePage.total }}</span></p>
              <el-link :underline="false" :disabled="tablePage.currentPage==tablePage.total ? true : false" @click="nextPage"><i class="el-icon-right"/></el-link>
            </div>
          </div>
        </div>
      </div>
      <div class="case-right" :style="'background: url('+require('@/assets/img/solution/'+caseInfo.customerStories.image)+') no-repeat'"/>
    </div>
  </div>
</template>
<script>
import solution from '@/static/solution.json'
export default {
  name: "cases",
  data() {
    return {
      caseInfo:{
        customerStories: {},
        title:'',
      },
      tablePage: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      caseList:[],
    }
  },
  methods: {
    /*上一条*/
    lastPage(){
      this.tablePage.currentPage--;
      this.caseInfo=this.caseList[this.tablePage.currentPage-1];
      let reg = new RegExp('\n', 'g')//g代表全部
      if (this.caseInfo.customerStories.enterpriseIntroduction!=''){
        if (this.caseInfo.customerStories.enterpriseIntroduction.indexOf('\n') > -1) {
          this.caseInfo.customerStories.enterpriseIntroduction= this.caseInfo.customerStories.enterpriseIntroduction.replace(reg, '<br/>');
        }
      }
    },
    /*下一条*/
    nextPage(){
      this.tablePage.currentPage++;
      let reg = new RegExp('\n', 'g')//g代表全部
      this.caseInfo=this.caseList[this.tablePage.currentPage-1];
      if (this.caseInfo.customerStories.enterpriseIntroduction!=''){
        if (this.caseInfo.customerStories.enterpriseIntroduction.indexOf('\n') > -1) {
          this.caseInfo.customerStories.enterpriseIntroduction= this.caseInfo.customerStories.enterpriseIntroduction.replace(reg, '<br/>');
        }
      }
    },
    /*客户案例详情*/
    caseDetails(){
      this.$router.push({
        path:'/caseDetails',
        query:{
          id:this.caseInfo.id,
          industryId:this.caseInfo.industryId
        }
      })
    }
  },
  mounted() {

    for (let a of solution) {
      if (a.children.length > 0) {
        for (let b of a.children) {
          if (b.customerStories){
            this.tablePage.total++;
            this.caseList.push(b);
            this.caseInfo=this.caseList[0];
          }
        }
      }
    }
    console.log(this.caseList);
  }
}
</script>
<style scoped>
.case-container >>> .el-link.el-link--default {
  color: #ffffff;
  font-size: 14px;
  font-family: 'SourceHanSansCN';
}

.case-container >>> .el-icon-arrow-right {
  margin-left: 20px;
  font-weight: bold;
}
</style>
<style scoped lang="scss">
.case-container {
  background: #FFFFFF;
  padding: 60px 0px 0px;

  .case-title {
    text-align: center;

    .title {
      font-size: 30px;
      font-family: 'SourceHanSansCN';
      color: #333333;
      margin-block-start: 0px;
      margin-block-end: 0px;
      font-weight: 500;
    }

    .case-tips {
      font-family: 'SourceHanSansCN';
      color: #666666;
      font-size: 18px;
      margin-block-start: 10px;
      margin-block-end: 40px;
    }
  }

  .case-content {
    width: 100%;
    height: 360px;
    display: flex;
    align-items: center;

    .case-left {
      width: 50%;
      height: 360px;
      background: linear-gradient(90deg, rgba(107, 159, 255, 0.99), rgba(25, 53, 222, 0.99));

      .case--content {
        width: 560px;
        float: right;
        padding-right: 40px;

        .case--title {
          font-size: 24px;
          color: #FFFFFF;
          font-family: 'SourceHanSansCN';
          margin-block-start: 60px;
          margin-block-end: 20px;
        }

        .content {
          font-family: "SourceHanSansCN";
          font-size: 16px;
          color: #FFFFFF;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          height: 170px;
          margin-block-end: 0px;
        }
        .case-content-bottom{
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
          .page-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .el-icon-back{
              padding: 8px;
              background: #C1CAFF;
              border-radius: 50%;
              color: #ffffff;
            }
            .el-icon-right{
              padding: 8px;
              background: #C1CAFF;
              border-radius: 50%;
              color: #ffffff;
            }
            .page-size{
              color: #FFFFFF;
              font-size: 18px;
              font-family: 'SourceHanSansCN';
              padding: 0px 5px;
              span{
                padding:0px 10px;
              }
            }
          }
        }
      }
    }

    .case-right {
      width: 50%;
      height: 360px;
      background-size: cover !important;
    }
  }
}
</style>
